<template>
  <div id="tutoringContent" class="app_desktop review-home">
    <div class="review-home__spot">
      <h1 class="spot__heading">사용자 리뷰</h1>
      <div class="spot__scroll">
        <ul class="spot__list" id="videoReviewList">
          <!--수강후기 영상 리뷰 Item -->
          <div id="template__interviewTestimonyItem">
            <li class="list__item">
              <a class="interview_uri" target="_blank">
                <div class="spot__item">
                  <div class="spot__thumb" style=""></div>
                  <div class="spot__detail">
                    <strong class="detail__title"></strong>
                    <div class="detail__hash"></div>
                  </div>
                </div>
              </a>
            </li>
          </div>
        </ul>
      </div>
    </div>

    <div class="review-home__recommend">
      <h1 class="recommend__heading">추천 후기</h1>
      <span class="recommend__description">내 공부 목적에 맞는 리뷰를 살펴보세요.</span>
      <div class="recommend__hash">
        <div class="hash__inner">
          #오픽 #면접 #아이엘츠 #토익스피킹 #기초문장 #발음교정 #비즈니스 #원어민표현 #미드/영화 #워홀/연수 #유학
          #키즈회화 #부모영어 #여행 대비
        </div>
      </div>
      <div class="recommend__content">
        <ul class="recommend__list" id="recommendReviewList">
          <!--수강후기 추천 리뷰 Item -->
          <div id="template__recommendTestimonyItem">
            <li class="list__item">
              <a class="recommend_uri" target="_blank">
                <div class="recommend__item">
                  <div class="recommend__thumb" style=""></div>
                  <div class="recommend__detail">
                    <span class="detail__user">arial님</span>
                    <strong class="detail__title">
                      원어민과 시간, 장소 상관없이 살아있는 영어를 공부 할 수 있어서 좋아요 추천합니다
                    </strong>
                    <p class="detail__description">
                      영어에 대한 두려움은 사라지고 자신감이 생겼어요! 주입식 학습보다 훨씬 효과적이고, 무엇보다 영어를
                      즐겁게 공부할 수 있어요!
                    </p>
                    <div class="detail__hash">#초급 #비즈니스 #여행 #패션</div>
                  </div>
                </div>
              </a>
            </li>
          </div>
        </ul>
      </div>
    </div>

    <div class="review-home__store">
      <h1 class="store__heading">스토어 리뷰</h1>
      <div class="store__sort">
        <select id="selectSequence">
          <option>최신순</option>
          <option>과거순</option>
        </select>
      </div>
      <div class="store__content">
        <ul class="store__list" id="appReviewList">
          <!--수강후기 스토어 리뷰 Item -->
          <div id="template__storeTestimonyItem">
            <li class="list__item">
              <div class="store__detail">
                <div class="store__score">
                  <div class="score__star"><span class="bar" style="width: 100%"></span></div>
                  <span class="score__text"><i></i></span>
                </div>
                <strong class="detail__title"></strong>
                <p class="detail__desc"></p>
                <span class="detail__date"></span>
                <span class="detail__user"></span>
              </div>
            </li>
          </div>
        </ul>
      </div>
    </div>

    <!--수강후기 추천 리뷰 Item -->
    <div id="template__recommendTestimonyItem">
      <li class="list__item">
        <a class="recommend_uri" target="_blank">
          <div class="recommend__item">
            <div class="recommend__thumb" style=""></div>
            <div class="recommend__detail">
              <span class="detail__user">arial님</span>
              <strong class="detail__title">
                원어민과 시간, 장소 상관없이 살아있는 영어를 공부 할 수 있어서 좋아요 추천합니다
              </strong>
              <p class="detail__description">
                영어에 대한 두려움은 사라지고 자신감이 생겼어요! 주입식 학습보다 훨씬 효과적이고, 무엇보다 영어를 즐겁게
                공부할 수 있어요!
              </p>
              <div class="detail__hash">#초급 #비즈니스 #여행 #패션</div>
            </div>
          </div>
        </a>
      </li>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Review',
};
</script>

<style></style>
